@import url(https://fonts.googleapis.com/css?family=Varela+Round);
html,
body {
    font-weight: normal;
    color: #7b7b7b;
    background-color: #f5f4f5;
    -webkit-font-smoothing: antialiased;
    font-family: 'Varela Round', sans-serif;
    /*overflow-x: hidden;*/
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Varela Round', sans-serif;
    font-weight: normal;
}

.tr-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

a,
a:hover,
a:focus,
input:focus {
    text-decoration: none;
    outline: none;
    color: #00b365;
}

a:hover {
    color: #00b365;
}

a:active,
a:focus, button:focus, .btn:focus,
input:focus, textarea:focus, select:focus,
.form-control, .form-control:focus {
    outline: none !important;
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.section-title h1 {
    font-size: 24px;
    color: #505050;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 45px;
}

.bg-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.section-padding {
    padding: 50px 0;
}

.page-content {
    margin: 80px 0;
}

.btn {
    color: #fff;
    padding: 8px 20px 5px;
    border-radius: 30px;
    font-size: 14px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.btn.btn-primary {
    padding: 8px 20px 5px;
    text-transform: uppercase;
    background-color: #12ad9e;
    border-color: #12ad9e;
}

.btn i {
    float: left;
    font-size: 20px;
    margin-right: 10px;
}

.btn.button-send {
    margin-right: 10px;
    background-color: #8322cb;
    border-color: #8322cb;
}

.btn.button-send:hover {
    color: #8322cb;
}

.btn:before {
    position: absolute;
    content: "";
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: -1;
}

.btn:hover:before {
    right: 0;
}

.btn:hover {
    color: #00b365;
}

/*.btn:focus {*/
/*border-color: #00b365;*/
/*}*/

.section-before {
    position: relative;
    z-index: 1;
}

.section-before:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: #1D2731;*/
    background-color: rgba(44, 55, 62, 0.8);
    z-index: -1;
}

.section {
    padding: 20px 40px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 30px;
    border-radius: 4px;
}

.tr-title {
    color: #4cba19;
    display: block;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
}

.form-control {
    height: 45px;
    font-size: 14px;
    padding: 5px 20px;
    color: #2d3038;
    margin-bottom: 20px;
    border: 1px solid #e8e6e8;
    background-color: #fff;
}

.form-control:focus {
    color: #2d3038;
    border-color: #00b365;
}

.title-after {
    padding-bottom: 15px;
    position: relative;
}

.title-after:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: -50px;
    width: 150%;
    height: 1px;
    background-color: #f5f4f5;
}

.user-title h1 {
    color: white;
    font-size: 36px;
    margin: 0;
}

.bookmark .remove-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    background-color: #e6e3e6;
    border-radius: 100%;
    z-index: 99999;
}

.remove-icon {
    cursor: pointer;
}

.bookmark .remove-icon:hover {
    background-color: #00b365;
}

.jqte_editor {
    resize: none;
}

/* tr-breadcrumb */

.tr-breadcrumb {
    color: #fff;
    padding: 40px 0;
    overflow: hidden;
    /*background-image: url(../images/bg/banner.jpg);*/
}

.page-title h1 {
    font-size: 48px;
    margin: 0;
    text-transform: capitalize;
}

.tr-breadcrumb .job-meta {
    margin-top: 0;
}

.breadcrumb {
    padding: 0;
    border: 0;
    font-size: 18px;
    margin-bottom: 0;
    background-color: transparent;
}

.breadcrumb li.active,
.breadcrumb li a,
.breadcrumb > li + li:before,
.tr-breadcrumb .job-meta li,
.tr-breadcrumb .job-meta li a {
    color: #fff;
}

.user-image {
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
         align-items: center;
    -moz-box-pack: center;
         justify-content: center;
}

.breadcrumb-logo img,
.user-image img {
    width: 100%;
    display: inline-block;
}

.user-image,
.breadcrumb-logo {
    width: 130px;
    height: 130px;
    overflow: hidden;
    display: inline-block;
    border-radius: 100%;
    /*margin-bottom: 30px;*/
}

.breadcrumb-logo {
    width: 170px !important;
}

.user-image1 {
    /*width: 130px;*/
    /*height: 130px;*/
    width: auto;
    height: auto;
    overflow: hidden;
    display: inline-block;
    border-radius: 100%;
    border: 2px solid black;
}

.user-image1 img {
    height: 130px;
    width: 130px;
    border-radius: 100%;
}

.user-image {
    border: 3px solid #fff;
}

.tr-breadcrumb .job-meta li {
    font-size: 14px;
}

.breadcrumb-social {
    margin-top: 35px;
}

.breadcrumb-social li {
    display: inline-block;
    font-size: 18px;
    margin-right: 10px;
}

.breadcrumb-social li:last-child {
    margin-right: -0px;
}

.breadcrumb-social li a i {
    color: #fff;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
}

.breadcrumb-social li a {
    display: block;
    position: relative;
    z-index: 9;
}

.breadcrumb-social span,
.button-share span {
    color: #2d373d;
    font-size: 12px;
    display: inline-block;
    padding: 5px 10px;
    background-color: #f5f5f6;
    border-radius: 30px;
    position: absolute;
    bottom: -70px;
    left: 50%;
    min-width: 90px;
    opacity: 0;
    visibility: hidden;
    z-index: 99999999999;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
}

.breadcrumb-social span:after,
.button-share span:after {
    content: '';
    position: absolute;
    top: -10px;
    left: 50%;
    margin-left: -7px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid #f5f5f6;
}

.breadcrumb-social li a:hover span {
    opacity: 1;
    visibility: visible;
    bottom: -40px;
}

.social-icon-bg i.fa-facebook {
    background-color: #344f88;
}

.social-icon-bg i.fa-twitter {
    background-color: #2696cf;
}

.social-icon-bg i.fa-instagram {
    background-color: #C13584;
}

.social-icon-bg i.fa-google-plus {
    background-color: #d34132;
}

.social-icon-bg i.fa-linkedin {
    background-color: #146ba4;
}

.social-icon-bg i.fa-youtube {
    background-color: #c4302b;
}

.social-icon-bg i.fa-behance {
    background-color: #050607;
}

.social-icon-bg i.fa-globe {
    background-color: #e96324;
}

/* Header */

.navbar.navbar-default {
    margin-bottom: 0;
    border-radius: 0;
    border: 0;
    background-color: #fff;
}

.navbar-brand {
    height: 80px;
    width: 100%;
    padding: 5px;
}

.navbar-brand .logo {
    height: 100%;
    width: auto;
}

.navbar-default .navbar-nav li a {
    font-size: 12px;
    color: #0a0a0a;
    display: block;
    text-transform: uppercase;
}

.navbar-default .navbar-nav > li > a {
    padding: 30px 10px 0 20px;
    line-height: 25px;
}

.navbar-default .navbar-nav li a:hover,
.navbar-default .navbar-nav li.active > a,
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li.active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:focus,
.navbar-default .navbar-nav > .active > a:hover,
.tr-dropdown-menu li a:hover {
    color: #00b365;
    background-color: transparent;
}

/* Navbar Right */

.tr-header .navbar-right {
    text-transform: uppercase;
    font-size: 12px;
    padding: 30px 0 0 0;
}

.tr-header .nav-right i {
    color: #00b365;
    margin-right: 5px;
}

.navbar-right .dropdown {
    display: inline;
    margin-right: 20px;
    padding: 22px 0;
}

.navbar-right .dropdown-menu {
    min-width: 150px;
}

.navbar-right .dropdown i,
.sign-in li i {
    color: #00b365;
}

.navbar-right .dropdown span {
    margin: 0 5px;
    color: #252525;
}

.navbar-right .dropdown-menu a,
.sign-in a {
    color: #252525;
    font-size: 12px;
}

.navbar-right .dropdown span:hover,
.sign-in a:hover,
.dropdown-menu li a:hover,
.dropdown-menu li a:focus {
    color: #00b365;
    background-color: transparent;
}

/* Navbar Toggle */

.navbar-default .navbar-toggle {
    border-color: #00b365;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #00b365;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: transparent;
}

/*Dropdown CSS*/

.tr-dropdown {
    position: relative;
}

.tr-dropdown-menu li a,
.dropdown-menu li a {
    padding: 5px 20px;
    display: block;
}

.dropdown-menu {
    padding: 10px 0;
    border-radius: 0;
    border-top: 1px solid #ddd;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

.tr-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 10px 0;
    min-width: 180px;
    list-style: none;
    background-color: #fff;
    border-top: 1px solid #ddd;
    z-index: 9999;
    display: none;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    -ms-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    -o-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.tr-dropdown-menu.left {
    left: 0;
}

.tr-dropdown:hover > .tr-dropdown-menu {
    display: block;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
    -webkit-animation: fadeInUp 400ms;
    -moz-animation: fadeInUp 400ms;
    animation: fadeInUp 400ms;
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
             transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
             transform: translateY(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
             transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
             transform: translateY(0);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
         animation-name: fadeInUp;
}

.sign-in {
    display: inline;
    margin-right: 35px;
}

.sign-in li i {
    margin-right: 5px;
}

.sign-in li {
    display: inline-block;
}

.sign-in li:last-child {
    position: relative;
    margin-left: 10px;
}

.sign-in li:last-child:before {
    position: absolute;
    left: -11px;
    top: 50%;
    /*background-image: url(../images/others/sign-in-before.png);*/
    background-repeat: no-repeat;
    width: 7px;
    height: 10px;
    content: "";
    margin-top: -7px;
}

.tr-header .btn.btn-primary {
    padding: 8px 20px 5px;
    font-size: 12px;
}

/* Tr Banner */

.tr-banner {
    color: #fff;
    padding: 70px 0;
    /*background-image: url(../images/bg/banner.jpg);*/
}

.banner-content h1 {
    font-size: 48px;
    margin-top: 0;
}

.banner-content h2 {
    font-size: 24px;
    margin-bottom: 0;
}

.banner-form {
    text-align: center;
    margin-top: 35px;
}

.banner-form form {
    background-color: #fff;
    position: relative;
    border-radius: 30px;
    max-width: 730px;
    display: inline-block;
}

.banner-form input,
.banner-form .dropdown {
    background-position: 20px center;
    background-repeat: no-repeat;
    font-size: 16px;
}

.banner-form .dropdown li a {
    line-height: 20px;
}

.banner-form .dropdown {
    width: 290px;
    line-height: 45px;
    text-align: left;
    background-position: 10px center;
}

.banner-form .dropdown-menu {
    min-width: 100%;
}

.banner-form .dropdown-menu a {
    font-size: 16px;
}

.banner-form .dropdown a {
    display: block;
}

.banner-form .dropdown a i {
    float: right;
    margin-top: 15px;
}

.banner-form .dropdown a {
    font-size: 16px;
    margin: 0;
    color: #000;
    border: none;
    border-radius: 5px 0 0 5px;
    text-transform: capitalize;
}

.banner-form input {
    width: 325px;
    padding: 0 45px;
    color: #11414e;
    border: none;
    border-right: 1px solid #cdcdcd;
    border-radius: 30px 0 0 30px;
    /*background-image: url(../images/others/search-bg.png);*/
}

.banner-form input:focus {
    border-right-color: #cdcdcd;
}

.banner-form input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #11414e;
}

.banner-form input::-moz-placeholder { /* Firefox 19+ */
    color: #11414e;
}

.banner-form input:-ms-input-placeholder { /* IE 10+ */
    color: #11414e;
}

.banner-form input:-moz-placeholder { /* Firefox 18- */
    color: #11414e;
}

.banner-form .dropdown a {
    color: #11414e;
}

.banner-form .form-control,
.banner-form button.btn-primary,
.banner-form .dropdown {
    float: left;
    height: 46px;
    margin-bottom: 0;
    background-color: #fff;
}

.banner-form button.btn-primary {
    color: #fff;
    font-size: 14px;
    width: 114px;
    margin-right: -1px;
    padding: 0;
    line-height: 47px;
    background-color: #00b365;
    border: 1px solid #00b365;
    border-radius: 0 30px 30px 0;
    text-transform: uppercase;
}

.banner-form button.btn-primary:before {
    display: none;
}

.dropdown a:hover {
    color: #00b365;
}

/* Homepage 2 Css */

.homepage-2 .navbar-default .navbar-nav li a:hover,
.homepage-2 .navbar-default .navbar-nav li.active > a,
.homepage-2 .navbar-default .navbar-nav > li > a:hover,
.homepage-2 .navbar-default .navbar-nav > li > a:focus,
.homepage-2 .navbar-default .navbar-nav > li.active > a,
.homepage-2 .navbar-default .navbar-nav > .active > a:hover,
.homepage-2 .navbar-default .navbar-nav > .active > a,
.homepage-2 .navbar-default .navbar-nav > .active > a:focus,
.homepage-2 .navbar-default .navbar-nav > .active > a:hover,
.homepage-2 .tr-dropdown-menu li a:hover,
.homepage-2 .navbar-right .dropdown i,
.homepage-2 .sign-in li i,
.homepage-2 .dropdown a:hover,
.homepage-2 .sign-in a:hover,
.homepage-2 .navbar-right .dropdown span:hover,
.homepage-2 .footer a:hover:hover {
    color: #f26522;
}

.form-control:focus {
    border-color: #f26522;
}

.homepage-2 .tr-download-app.section-before:before {
    background-color: rgba(242, 101, 34, 0.9);
}

.homepage-2 .navbar-default .navbar-toggle {
    border-color: #f26522;
}

.homepage-2 .navbar-default .navbar-toggle .icon-bar {
    background-color: #f26522;
}

.tr-users {
    color: #fff;
    padding: 100px 0;
    position: relative;
    /*background-image: url(../images/bg/user-bg.jpg);*/
    background-color: #c9c9c9;
    z-index: 1;
}

.tr-users:before,
.tr-users:after {
    position: absolute;
    content: "";
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
}

.tr-users:before {
    left: 0;
    background-color: rgba(247, 148, 29, 0.92);
}

.tr-users:after {
    right: 0;
    background-color: rgba(242, 101, 34, 0.92);
}

.tr-users img {
    display: inline-block;
}

.tr-users span {
    display: block;
    margin-bottom: 30px;
}

.tr-users .btn {
    font-size: 12px;
    padding: 7px 30px 5px;
}

.tr-users .job-find .btn.btn-primary {
    border-color: #232a2f;
    background-color: #232a2f;
}

.tr-users .job-find .btn:hover {
    border-color: #fff;
}

.tr-users .for-employers .btn.btn-primary {
    color: #f26522;
    border-color: #fff;
    background-color: #fff;
}

.tr-users .for-employers .btn.btn-primary:before {
    background-color: #232a2f;
}

.tr-users .for-employers .btn.btn-primary:hover {
    color: #fff;
    border-color: #232a2f;
}

.homepage-2 .job-tab .nav-tabs > li.active > a,
.homepage-2 .job-tab .nav-tabs > li.active > a:focus,
.homepage-2 .job-tab .nav-tabs > li.active > a:hover,
.homepage-2 .job-tab .nav-tabs > li > a:hover {
    background-color: #f26522;
}

.homepage-2 .job-item .item-overlay {
    background-color: #f26522;
}

.homepage-2 .item-overlay .btn.btn-primary {
    background-color: #d9591e;
    border-color: #d9591e;
}

.homepage-2 .item-overlay .btn.btn-primary:hover {
    color: #d9591e;
}

.homepage-2 .job-social li a {
    background-color: #d9591e;
    border-color: #d9591e;
}

.homepage-2 .job-social li:last-child a i {
    color: #f26522;
}

.homepage-2 .job-social li:last-child a:hover i {
    color: #fff;
}

.homepage-2 .job-social li a:before {
    background-color: #f26522;
}

.homepage-2 .btn.btn-primary {
    background-color: #f26522;
    border-color: #f26522;
}

.homepage-2 .btn.btn-primary:hover {
    color: #f26522;
}

/* Tr Testimonial 2 */

.tr-testimonial-2 {
    margin-bottom: 100px;
}

.testimonial-info {
    font-size: 16px;
    font-style: italic;
    line-height: 27px;
    color: #8f8d8f;
    padding: 20px;
    position: relative;
    background-color: #fff;
    margin-bottom: 30px;
    height: 150px;
    cursor: default;
}

.testimonial-info:hover {
    -webkit-transform: scale(1.01);
       -moz-transform: scale(1.01);
            transform: scale(1.01);
    -webkit-transition: 0.2s all linear;
    -moz-transition: 0.2s all linear;
    transition: 0.2s all linear;
}

.testimonial-info:after {
    position: absolute;
    content: "";
    left: 50%;
    margin-left: -5px;
    bottom: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
}

.testimonial-image {
    width: 130px;
    height: 130px;
    border: 4px solid #00b365;
    border-radius: 100%;
    overflow: hidden;
    display: inline-block;
}

.testimonial-image img {
    width: 130px;
    height: 130px;
}

/* Tr Category */

.tr-category {
    text-align: center;
}

.category-list {
    border-radius: 4px;
    overflow: hidden;
}

.category-list li {
    float: left;
    width: 25%;
    font-size: 16px;
    background-color: #fff;
    border-right: 1px solid #e6e3e6;
    border-bottom: 1px solid #e6e3e6;
}

.category-list.category-list-bottom li {
    border-bottom: 0;
}

.category-list li:last-child {
    border-right: 0;
}

.category-list li a {
    color: #b5b5b5;
    display: block;
    padding: 40px 15px;
}

.category-list span {
    display: block;
}

.category-list .category-title {
    color: #505050;
}

.category-list .icon {
    margin-bottom: 5px;
    min-height: 40px;
}

.category-list .icon img {
    display: inline-block;
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    /*-ms-filter: grayscale(1);*/
    -o-filter: grayscale(1);
    filter: grayscale(1);
}

.category-list li:hover .icon img {
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    /*-ms-filter: grayscale(0);*/
    -o-filter: grayscale(0);
    filter: grayscale(0);
}

/* Tr ad */
.tr-ad {
    margin: 10px 0;
}

.tr-ad .ad-content {
    margin-bottom: 0;
    padding: 0;
}

.tr-ad .ad-content img {
    width: 100%;
}

.ad-info h1 {
    font-size: 24px;
    color: #505050;
    margin: 5px 0 0 120px;
}

/* Tr Job Post */

.job-item {
    background-color: #fff;
}

.job-tab .nav-tabs {
    margin-bottom: 60px;
    border-bottom: 0;
}

.job-tab .nav-tabs > li {
    float: none;
    display: inline;
}

.job-tab .nav-tabs li {
    margin-right: 15px;
    position: relative;
}

.job-tab .nav-tabs li:last-child {
    margin-right: 0;
}

.job-tab .nav-tabs li:before {
    position: absolute;
    content: "";
    top: 50%;
    left: -50%;
    width: 100%;
    height: 1px;
    background-color: #fff;
    z-index: 999;
}

.job-tab .nav-tabs li:first-child:before {
    display: none;
}

.job-tab .nav-tabs > li a {
    display: inline-block;
    background-color: #fff;
    border: none;
    border-radius: 30px;
    font-size: 14px;
    color: #000;
    z-index: 99999;
    padding: 5px 30px;
}

.job-tab .nav-tabs > li.active > a,
.job-tab .nav-tabs > li.active > a:focus,
.job-tab .nav-tabs > li.active > a:hover,
.job-tab .nav-tabs > li > a:hover {
    border: none;
    background-color: #00b365;
    color: #fff;
}

.job-item {
    border-radius: 3px;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
}

.job-item .btn.btn-primary {
    text-transform: capitalize;
}

.job-item .job-info {
    font-size: 14px;
    color: #000;
    overflow: hidden;
    padding: 40px 25px 20px;
}

.job-info .company-logo {
    margin-bottom: 30px;
}

.job-info .tr-title {
    margin-bottom: 15px;
}

.job-info .tr-title span {
    font-size: 14px;
    display: block;
}

.job-info .tr-title a {
    color: #000;
}

.job-info .tr-title a:hover {
    color: #00b365;
}

.job-info ul {
    margin-bottom: 30px;
}

.job-meta li,
.job-meta li a {
    color: #646464;
}

.job-meta li a:hover {
    color: #00b365;
}

.job-meta li {
    font-size: 12px;
    margin-bottom: 10px;
}

.job-meta li span i {
    color: #000;
}

.job-meta li i {
    margin-right: 15px;
}

.job-item .time {
    position: relative;
}

.job-item .time:after {
    position: absolute;
    content: "";
    bottom: 35px;
    left: -50px;
    width: 150%;
    height: 1px;
    background-color: #f5f4f5;
    z-index: -1;
}

.job-item:hover .time,
.job-item:hover .time:after {
    opacity: 0;
}

.job-item .time span {
    font-size: 12px;
    color: #bebebe;
    line-height: 25px;
}

.job-item .btn.btn-primary,
.role .btn.btn-primary,
.job-item .time a span {
    padding: 5px 10px;
    border-radius: 4px;
    line-height: 10px;
    font-size: 12px;
}

.job-item .time a span {
    color: #fff;
    background-color: #f1592a;
    border-color: #f1592a;
}

.job-item .time a span.part-time {
    background-color: #00aeef;
    border-color: #00aeef;
}

.job-item .time a span.freelance {
    background-color: #92278f;
    border-color: #92278f;
}

.job-item .item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-color: #00b365;
    color: #fff;
    opacity: 0;
    -webkit-transition: all 800ms;
    -moz-transition: all 800ms;
    transition: all 800ms;
}

.job-item:hover .item-overlay {
    opacity: 1;
}

.item-overlay .job-info {
    padding: 45px 25px 40px;
    overflow: hidden;
}

.item-overlay .btn.btn-primary {
    background-color: #007bd4;
    border-color: #007bd4;
    margin-bottom: 10px;
}

.item-overlay .job-info,
.item-overlay .job-info ul li,
.item-overlay .job-info ul li i,
.item-overlay .job-info .tr-title a {
    color: #fff;
}

.job-social {
    margin-top: 35px;
}

.job-social li {
    float: left;
}

.job-social li + li {
    margin-left: 15px;
}

.job-social li a i {
    margin-right: 0;
    font-size: 14px;
}

.job-social li a {
    width: 35px;
    height: 35px;
    text-align: center;
    display: block;
    background-color: #007bd4;
    line-height: 35px;
    border-radius: 100%;
    border: 1px solid #007bd4;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.job-social li:last-child a {
    background-color: #fff;
}

.job-social li:last-child a i {
    color: #00b365;
}

.job-social li a:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 100%;
    background-color: #00b365;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    transform: scale(0);
}

.job-social li a:hover:before {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
    padding: 5px;
}

.job-social li a:hover {
    border-color: #fff;
}

.job-social li a:hover i {
    color: #fff;
}

/* Tr Steps */

.tr-steps {
    /*background-image: url(../images/bg/step-bg.jpg);*/
    position: relative;
    z-index: 1;
}

.tr-steps .step {
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    padding: 20px 10px 10px;
}

.tr-steps .step-image {
    min-height: 75px;
    margin-bottom: 15px;
}

.tr-steps h1 {
    font-size: 20px;
    color: #00aeef;
    margin: 0;
}

.tr-steps h2 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 10px 0 30px;
}

/* Tr Recent News */

.tr-post {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px;
}

.entry-content {
    padding: 42px 40px;
}

.entry-title {
    font-size: 24px;
    margin-top: 0;
}

.entry-title a {
    color: #000;
}

.entry-meta li {
    float: left;
    color: #424242;
    font-size: 14px;
    margin-right: 20px;
}

.entry-meta li:last-child {
    margin-right: 0;
}

.entry-meta li a {
    color: #424242;
}

.entry-meta li i {
    margin-right: 10px;
}

.tr-post a:hover {
    color: #00aeef;
}

.eleft {
    color: red;
    font-size: 12px;
}

/* Tr Testimonial */
.testimonial {
    -webkit-box-shadow: 2px 2px 2px #ccc;
            box-shadow: 2px 2px 2px #ccc;
}

.tr-testimonial {
    background-color: #fff;
    margin: 50px 0;
}

.testimonial-content {
    padding: 140px 0;
    /*background-image: url(../images/bg/test.png);*/
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right;
}

.tr-testimonial .slick-prev,
.tr-testimonial .slick-next {
    display: none !important;
}

.tr-testimonial .media-left {
    min-width: 130px;
}

.tr-testimonial .media-left img {
    height: 120px;
    width: auto;
}

.testimonial-slider {
    color: #8f8f8f;
    font-style: italic;
    font-size: 24px;
    max-width: 760px;
    line-height: 30px;
}

.testimonial-title {
    font-style: normal;
    color: #000;
    font-size: 14px;
    line-height: 0;
}

.testimonial-title h2 {
    font-size: 18px;
}

/* Tr Fun Fact */

.fun-fact {
    background-color: #fff;
    border-radius: 4px;
    color: #a0a0a0;
    font-size: 14px;
    line-height: 17px;
    padding: 20px;
    margin-bottom: 20px;
    display: -moz-box;
    display: flex;
}

.fun-fact-icon {
    -moz-box-flex: 1;
         flex: 1 1;
}

.media-body {
    -moz-box-flex: 3;
         flex: 3 1;
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
         flex-direction: column;
    -moz-box-pack: center;
         justify-content: center;
}

.media-body h1 {
    margin-bottom: 10px !important;
}

.fun-fact-content {
    margin-top: 20px;
}

.fun-fact img {
    display: inline-block;
}

.fun-fact h1 {
    font-size: 30px;
    color: #3d464d;
    margin: 20px 0 0;
}

.fun-fact span {
    font-size: 16px;
    color: #3d464d;
    margin: 15px 0;
    display: block;
}

/* Tr Download App */

.tr-download-app {
    /*background-image: url(../images/bg/app-bg.jpg);*/
    position: relative;
    z-index: 1;
}

.tr-download-app.section-before:before {
    background-color: rgba(0, 141, 239, 0.8);
}

.tr-download-app h1 {
    font-size: 26px;
    color: #fff;
    text-align: center;
    margin: 15px 0 45px;
    text-transform: capitalize;
}

.tr-download-app h2 {
    color: #fff;
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 50px;
}

.app-content {
    padding: 0 80px;
}

.download-app a {
    border: 1px solid #fff;
    padding: 10px;
    border-radius: 5px;
    display: block;
    overflow: hidden;
    min-height: 75px;
    color: #fff;
    font-size: 16px;
    margin-bottom: 20px;
}

.download-image {
    display: inline-block;
    margin-right: 15px;
}

.download-image img {
    display: inline-block;
    margin-top: -20px;
}

.download-info {
    display: inline-block;
    text-align: left;
}

.download-app strong {
    font-size: 25px;
    display: block;
    font-weight: normal;
    line-height: 20px;
}

.download-app a:hover,
.download-app a:focus {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    border-radius: 5px;
}

/* Job Details */

.tr-breadcrumb .buttons {
    margin-top: 50px;
}

.tr-breadcrumb .buttons a {
    margin-right: 15px;
}

.tr-breadcrumb .buttons a:last-child {
    margin-right: 0;
}

.btn.button-bookmark {
    border-color: #12ad9e;
    background-color: #12ad9e;
}

.btn.button-bookmark:hover {
    color: #89c040;
}

.btn.button-share:before {
    display: none;
}

.btn.button-share {
    overflow: visible;
    border-color: #25292e;
    background-color: #25292e;
}

.btn.button-share:hover {
    color: #25292e;
    background-color: #fff;
}

.button-share span:after {
    border-bottom: 10px solid #fff;
}

.button-share span {
    padding: 10px 15px;
    background-color: #fff;
    text-transform: capitalize;
}

.button-share span a:hover i.fa-facebook {
    color: #344f88;
}

.button-share span a:hover i.fa-twitter {
    color: #2696cf;
}

.button-share span a:hover i.fa-google-plus {
    color: #d34132;
}

.button-share span {
    padding: 5px 15px;
}

.button-share:hover span {
    opacity: 1;
    visibility: visible;
    bottom: -47px;
    line-height: 0;
}

.btn.button-share a {
    margin: 0;
    color: #2d373d;
    padding: 5px;
    display: inline-block;
}

.btn.button-share span a i {
    margin: 0;
    font-size: 14px;
}

.job-summary {
    font-size: 14px;
    color: #272727;
    border-radius: 4px;
    line-height: 22px;
    text-align: justify;
}

.job-summary span {
    display: block;
    font-size: 18px;
    margin: 50px 0 15px;
}

.job-summary span:first-of-type {
    margin-top: 0;
}

.job-summary ul li {
    line-height: 30px;
}

table, td, th {
    border: 1px solid #ddd;
    text-align: left;
}

table {
    border-collapse: collapse;
    width: 100%;
}

th, td {
    padding: 15px;
}

.job-details .tr-job-posted .tr-title {
    display: block;
    margin-bottom: 25px;
}

.job-details .widget_title {
    font-size: 18px;
}

.job-tabs {
    float: right;
    border: none;
}

.job-tabs > li {
    float: left;
    margin-right: 20px;
}

.job-tabs > li > a {
    padding: 0;
    color: #d4d3d4;
    border: none;
}

.job-tabs > li a:hover,
.job-tabs > li a:focus,
.job-tabs > li.active > a,
.job-tabs > li.active > a:focus,
.job-tabs > li.active > a:hover {
    border: none;
    color: #000;
    background-color: transparent;
}

.job-tabs > li:last-child {
    margin-right: 0;
}

.job-tabs li {
    padding: 0;
    font-size: 20px;
    cursor: pointer;
}

.job-tabs li:first-child {
    font-size: 14px;
    cursor: inherit;
    margin-top: 2px;
}

.job-topbar {
    color: #000;
    overflow: hidden;
    margin-bottom: 15px;
}

.list-menu {
    border-radius: 4px;
    margin-bottom: 30px;
}

.list-menu .dropdown-menu {
    min-width: 100%;
}

.list-menu > ul > li {
    float: left;
    width: 20%;
    color: #acacac;
    font-size: 14px;
    padding: 25px 15px;
    background-color: #fff;
    border-right: 1px solid #f5f4f5;
}

.list-menu li a {
    color: #000;
}

.list-menu li i {
    margin-left: 5px;
}

.list-menu li:last-child {
    border-right: 0;
}

/* Tr Sidebar */

.widget {
    color: #a9a9a9;
    padding: 15px 15px;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 10px;
}

.widget ul li {
    font-size: 14px;
    padding: 5px 0;
}

.widget ul li a {
    color: #a9a9a9;
}

.widget ul li a:hover {
    color: #00b365;
}

.widget_title {
    color: #4cba19;
    margin: 0 0 10px;
    font-weight: 600;
}

.widget span {
    color: #4cba19;
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 16px;
}

.widget.short-info i {
    color: #0389e7;
    font-size: 18px;
    margin-right: 15px;
}

.widget.short-info li span {
    display: block;
}

.widget.cmpany-info > span {
    display: block;
    margin-bottom: 10px;
}

.widget.cmpany-info ul {

}

.widget.cmpany-info ul li {
    line-height: 20px;
}

.widget.cmpany-info ul li span {
    font-size: 14px;
}

.widget-social {
    overflow: hidden;
    margin-top: 5px;
}

.widget-social ul li {
    float: left;
    font-size: 18px;
    margin-right: 20px;
}

.widget-social ul li:last-child {
    margin-right: 0;
}

.widget-social .fa-facebook {
    background-color: #0072bc;
    padding: 3px;
    border-radius: 50%;
    color: #fff;
    height: 20px;
    width: 20px;
}

.widget-social .fa-twitter {
    background-color: #00aeef;
    padding: 3px;
    border-radius: 50%;
    color: #fff;
    height: 20px;
    width: 20px;
}

.widget-social .fa-google-plus {
    background-color: #eb434a;
    padding: 3px;
    border-radius: 50%;
    color: #fff;
    height: 20px;
    width: 20px;
}

.widget-social .fa-linkedin {
    background-color: #448ccb;
    padding: 3px;
    border-radius: 50%;
    color: #fff;
    height: 20px;
    width: 20px;
}

.widget-social .fa-instagram {
    background-color: #E1306C;
    padding: 3px;
    border-radius: 50%;
    color: #fff;
    height: 20px;
    width: 20px;
}

.widget-social .fa-youtube {
    background-color: #c4302b;
    padding: 3px;
    border-radius: 50%;
    color: #fff;
    height: 20px;
    width: 20px;
}

.widget-social .fa-instagram {
    background-color: #C13584;
    padding: 1px;
    border-radius: 50%;
    color: #fff;
    height: 21px;
    width: 21px;
}

/* User Account */

.user-account {
    overflow: hidden;
}

.user-account,
.user-account a {
    color: #bbc0d4;
}

.account-content {
    padding: 35px 30px;
    border-radius: 4px;
    background-color: #fff;
}

.user-account .form-control,
.user-account .dropdown {
    padding: 5px 20px;
    border-radius: 30px;
    margin-bottom: 30px;
    background-color: #fcfcfc;
}

.user-account .dropdown {
    border: 1px solid #e8e6e8;
}

.user-account .dropdown a {
    display: block;
}

.user-account .dropdown {
    padding: 12px 20px;
}

.user-account .dropdown-menu {
    width: 100%;
}

.user-account .dropdown i {
    float: right;
    margin-top: 2px;
}

.user-account .btn {
    width: 100%;
    padding: 12px 20px 8px;
    margin-bottom: 25px;
}

.user-option {
    overflow: hidden;
    margin-bottom: 25px;
}

.checkbox {
    float: left;
    margin: 0;
}

.forgot-password {
    float: right;
}

.checkbox label {
    padding-left: 30px;
}

.checkbox label input[type="checkbox"] {
    width: 0;
    height: 0;
    display: none;
}

.checkbox label:before {
    position: absolute;
    color: #bbc0d4;
    content: "";
    left: 0;
    top: 0;
    width: 15px;
    height: 15px;
    border: 1px solid #bbc0d4;
    border-radius: 100%;
    background-color: #fcfcfc;
}

.checkbox label:after {
    position: absolute;
    color: #00b365;
    content: "\f00c";
    left: 3px;
    top: 7px;
    font-family: "fontawesome";
    font-size: 10px;
    line-height: 0;
    display: none;
}

.checkbox label.checked,
.user-account a:hover {
    color: #00b365;
}

.checkbox label.checked:before {
    border-color: #00b365;
}

.checkbox label.checked::after {
    display: block;
}

.form-control option {
    padding: 10px;
}

.nav-tabs.nav-justified {
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #f5f4f5;
    background-color: #fff;
}

.nav-tabs.nav-justified > li + li {
    border-left: 1px solid #fff;
}

.nav-tabs.nav-justified > li > a {
    font-size: 16px;
    color: #2a343a;
    border: none;
    border-radius: 0;
}

.nav-tabs.nav-justified > .active > a,
.nav-tabs.nav-justified > .active > a:focus,
.nav-tabs.nav-justified > .active > a:hover,
.nav-tabs.nav-justified > li > a:hover {
    border: 0;
    color: #fff;
    background-color: #00b365;
}

.tab-content .account-content {
    border-radius: 0px 0px 4px 4px;
}

/* Tr Profile */

.tr-profile {
    color: #272727;
    line-height: 22px;
}

.account-info .fun-fact {
    padding: 20px 20px;
}

.account-info .fun-fact h1 {
    margin: 0;
}

.account-info .fun-fact span {
    margin: 0;
}

.account-info .fun-fact-icon {
    float: left;
    margin-right: 15px;
}

.resume-info > li {
    margin-bottom: 30px;
    position: relative;
}

.profile-tabs {
    border: 0;
    padding: 40px 30px;
}

.profile-tabs > li i {
    font-size: 18px;
    color: #00b365;
    margin-right: 10px;
}

.profile-tabs > li {
    float: none;
    font-size: 16px;
    margin-bottom: 20px;
}

.profile-tabs > li:last-child {
    margin-bottom: 0;
}

.profile-tabs > li > a {
    color: #707070;
    margin-right: 0;
    border: 0;
    border-radius: 0;
    display: block;
    padding: 10px 0;
}

.profile-tabs > li > a:hover,
.profile-tabs > li > a:focus,
.profile-tabs > li.active > a,
.profile-tabs > li.active > a:focus,
.profile-tabs > li.active > a:hover {
    color: #00b365;
    border: 0;
    background-color: transparent;
}

.tr-sidebar .btn i {
    float: none;
    display: inline-block;
    margin-right: 5px;
}

.tr-sidebar .btn span {
    margin-top: 0;
}

.resume-info .icon {
    float: left;
    font-size: 30px;
    color: #00b365;
    margin-right: 30px;
}

.resume-info .media-body .icon {
    font-size: 14px;
    width: 35px;
    height: 35px;
    color: #fff;
    line-height: 35px;
    text-align: center;
    background-color: #00b365;
    border-radius: 4px;
}

.resume-info .media-body .icon a {
    color: #fff;
    display: block;
}

.resume-info label {
    color: #bebec0;
}

.work-history ul li,
.education-background .media-body > ul > li,
.company-achievement .media-body > ul > li {
    overflow: hidden;
    margin-bottom: 30px;
    padding-bottom: 25px;
    border-bottom: 1px solid #f1f1f1;
}

.work-history ul li:last-child,
.education-background .media-body > ul > li:last-child,
.company-achievement .media-body > ul > li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

.work-history span,
.education-background span,
.company-achievement span {
    display: block;
}

.resume-info .media-body ul > li > span {
    font-weight: 500;
    color: #2d3038;
}

.resume-info .media-body ul > li > span.present {
    font-weight: normal;
}

.work-history .present,
.company-achievement li ul {
    /*margin-bottom: 20px;*/
}

.tr-sidebar .btn {
    width: 100%;
    border-radius: 0;
    padding: 8px 20px;
    text-transform: capitalize;
}

.education-background li ul {
    /*margin-bottom: 20px;*/
    overflow: hidden;
}

.education-background li ul li {
    float: left;
    margin-right: 45px;
}

.education-background li ul li:last-child {
    margin-right: 0;
}

.qualification ol {
    margin: 0;
    padding-left: 15px;
}

.qualification ol li,
.personal-deatils li {
    margin-bottom: 10px;
}

.language-proficiency li {
    float: left;
}

.language-proficiency .media-body > ul > li {
    margin-right: 55px;
    margin-bottom: 10px;
    position: relative;
}

.language-proficiency .media-body > ul > li:last-child,
.language-proficiency .rating li:last-child {
    margin-right: 0;
}

.language-proficiency .media-body > ul > li + li:before {
    position: absolute;
    content: "";
    top: 0;
    left: -27px;
    width: 1px;
    height: 100%;
    background-color: #f1f1f1;
}

.language-proficiency li ul {
    margin-top: 5px;
}

.language-proficiency .rating li {
    color: #00b365;
    margin-right: 5px;
}

.personal-deatils .media-body ul > li > span {
    font-weight: normal;
}

.personal-deatils .left {
    min-width: 180px;
    float: left;
}

.personal-deatils .middle {
    margin-right: 30px;
    float: left;
}

.personal-deatils .right {
    display: table-cell;
    vertical-align: top;
    width: 10000px;
}

.personal-deatils label {
    width: 100%;
    font-weight: normal;
    margin-top: 10px;
    color: #2d3038;
}

.personal-deatils .form-control {
    margin-bottom: 0;
}

.personal-deatils .calendar {
    margin-bottom: 20px;
}

.resume-info .buttons {
    margin-top: 50px;
    text-transform: uppercase;
}

.buttons .btn {
    padding: 10px 20px 8px;
    text-transform: uppercase;
}

.buttons > .btn {
    margin-right: 10px;
}

.btn.button-cancle {
    background-color: #dfdfdf;
    border-color: #dfdfdf;
}

.btn.button-cancle:hover {
    border-color: #00b365;
    color: #fff;
}

.btn.button-cancle:before {
    background-color: #00b365;
}

/* Account Info */

.resume-last-updated {
    padding: 10px 20px;
}

.account-info .icon {
    float: left;
    margin-right: 15px;
}

.account-info .title {
    margin-bottom: 30px;
}

.updated-info {
    margin-top: 5px;
    overflow: hidden;
}

.account-info .title span {
    margin-top: 5px;
    display: inline-block;
}

.change-photo {
    margin-bottom: 35px;
    overflow: hidden;
}

.change-photo .user-image {
    float: left;
    margin: 0 20px 0 0;
    border: none;
}

.change-photo .upload-photo {
    margin-top: 45px;
}

.change-photo .upload-photo .btn {
    font-size: 12px;
    cursor: pointer;
    margin-right: 15px;
    padding: 10px 20px 7px;
    text-transform: uppercase;
}

.change-photo .upload-photo input {
    display: none;
}

.account-details li {
    color: #bebec0;
    font-weight: 700;
}

.account-info li {
    overflow: hidden;
    margin-bottom: 20px;
}

.account-info li:last-child {
    margin-bottom: 0;
}

.account-details li span,
.account-info .social a {
    display: block;
    font-weight: normal;
    padding: 10px 20px;
    color: #2d3038;
    margin-top: 5px;
    border-radius: 4px;
    border: 1px solid #e8e6e8;
    background-color: #faf7fa;
}

.account-details li a {
    color: #2d3038;
}

.account-details li a:hover {
    color: #00b365;
}

.account-info .social i {
    color: #fff;
    width: 45px;
    height: 45px;
    float: left;
    font-size: 18px;
    line-height: 45px;
    text-align: center;
    border-radius: 4px;
    margin-right: 20px;
}

.account-info .social a {
    margin-top: 0;
}

.account-info .social a:hover {
    color: #00b365;
}

/* Edit Resume */

.jqte {
    color: #2d3038;
    padding: 30px;
    background-color: #faf7fa;
    border: 1px solid #e8e6e8;
    border-radius: 4px;
}

.code-edit p:last-of-type {
    margin-bottom: 0;
}

.edit-resume .checkbox {
    margin-top: 38px;
}

.edit-resume .checkbox label {
    font-size: 14px;
    color: #2d3038;
}

.calendar .form-control {
    padding: 12px 20px;
}

.edit-resume .checkbox label:before {
    border-color: #e8e6e8;
    border-radius: 3px;
    width: 20px;
    height: 20px;
}

.edit-resume .checkbox label:after {
    font-size: 12px;
    top: 10px;
    left: 4px;
}

.edit-resume label.checked:before {
    border-color: #00b365;
}

.edit-resume .work-history ul li {
    margin-bottom: 25px;
    padding-bottom: 30px;
}

.edit-resume .work-history ul li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

.rating-star {
    height: 45px;
    line-height: 25px;
    padding: 10px 20px;
    overflow: hidden;
    border-radius: 4px;
    border: 1px solid #e8e6e8;
    background-color: #faf7fa;
    margin-bottom: 20px;
}

.rating-star .rating {
    font-size: 14px;
}

.code-edit-small .jqte {
    padding: 12px 20px;
    margin-bottom: 20px;
}

.rating-star .jqte {
    padding: 0;
    border: 0;
    margin: 0;
    min-width: 70px;
    float: left;
}

.rating-star .jqte_editor {
    overflow: hidden;
}

.rating > input[type="radio"] + label {
    cursor: default;
    margin: 0;
    padding: 0;
    color: #bbb;
    float: right;
    line-height: 18px;
    position: relative;
}

.rating > input {
    display: none;
}

.rating > label:before {
    margin: 3px;
    font-size: 13px;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
}

.rating > .half:before {
    content: "\f089";
    position: absolute;
}

.rating > input:checked ~ label,
.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
    color: #00b365;
}

.rating > input:checked + label:hover,
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label,
.rating > input:checked ~ label:hover ~ label {
    color: #00b365;
}

/* Apply Job */

.apply-job .job-meta,
.two-column .job-meta {
    overflow: hidden;
    margin: 10px 0 0;
}

.job-item .left-content,
.role .left-content {
    float: left;
}

.job-item .right-content,
.role .right-content {
    float: right;
}

.job-item .right-content span,
.role .right-content span {
    font-size: 24px;
    margin-top: 10px;
    display: inline-block;
}

.job-item .right-content span a,
.job-item .right-content span,
.role .right-content span,
.role .right-content span a {
    color: #e0e0e0;
}

.job-item .right-content span a:hover,
.job-item .right-content span:hover,
.role .right-content span:hover,
.role .right-content span a:hover {
    color: #00b365;
}

.apply-job .tr-title,
.open-role .tr-title,
.two-column .tr-title,
.archived-job .tr-title {
    float: left;
    margin: 5px 10px 0 0;
}

.apply-job .job-meta li,
.archived-job .job-meta li,
.two-column .job-meta li {
    float: left;
    margin-right: 20px;
    margin-bottom: 0;
}

.apply-job .job-meta li:last-child,
.two-column .job-meta li:last-child,
.archived-job .job-meta li:last-child {
    margin-right: 0;
}

.apply-job .company-logo,
.two-column .company-logo {
    margin-bottom: 0;
    margin-right: 10px;
    height: 100px;
    width: auto;
}

.logo {
    max-width: 100%;
}

.apply-job .job-info,
.two-column .job-info {
    padding: 30px 40px;
}

.apply-job .btn {
    margin: 5px 0 0 0;
}

.apply-job .button-cancle {
    padding: 10px 20px 8px;
    text-transform: uppercase;
}

.right-content span.applied {
    color: #bebebe;
    font-size: 12px;
    text-align: right;
    display: block;
    margin-top: 15px;
}

.right-content span.applied:hover {
    color: #bebebe;
}

/* archived-job */

.archived-job .tr-title,
.open-role .tr-title,
.two-column .tr-title {
    margin-top: 0;
}

.deadline {
    display: block;
    margin: 5px 0 0;
    font-size: 12px;
    color: #bebebe;
}

.archived-job .job-info,
.open-role .role {
    padding: 30px 40px;
}

/* Close Account */

.close-account {
    text-align: center;
    padding: 100px 15px;
}

.close-account h1 {
    margin: 0 0 15px;
}

.close-account span {
    display: block;
    font-size: 24px;
    margin-bottom: 40px;
}

/* Open Role */

.open-role .role {
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 4px;
    background-color: #fff;
}

.open-role .btn.btn-primary {
    text-transform: capitalize;
}

.role .right-content span {
    margin-right: 15px;
}

.role .right-content span:last-child {
    margin-right: 0;
}

.role .tr-title a {
    color: #000;
}

.role .tr-title a:hover {
    color: #00b365;
}

.role .job-meta {
    margin-top: 5px;
}

.role .job-meta li {
    float: left;
    margin-right: 20px;
    margin-bottom: 0;
}

.role .job-meta li:last-child {
    margin-right: 0;
}

/* Additem CSS*/

.additem {
    margin-bottom: 25px;
    padding-bottom: 30px;
    border-bottom: 1px solid #f1f1f1;
}

.additem .icon.clone,
.additem:first-of-type .icon.remove {
    display: none;
}

.additem:first-of-type .icon.clone {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    cursor: pointer;
    margin-right: 0;
}

.additem:last-of-type {
    border-bottom: 0;
}

.additem .icon.remove {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 0px;
    margin-top: -41px;
    margin-right: 0;
}

.additem .icon.clone,
.additem .icon.remove {
    height: 30px;
    width: 30px;
    line-height: 30px;
    border: 1px solid #00b365;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.additem .icon.clone:hover, .additem .icon.remove {
    background-color: #fff;
    color: #00b365;
}

.additem .icon.remove:hover {
    background-color: #00b365;
    color: #fff;
}

.education-background .additem:nth-child {
    padding-bottom: 40px;
}

/* Job Post */

.tr-post-job .tr-title {
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid #f2f2f2;
}

.tr-post-job .short-info .section {
    overflow: visible;
}

.tr-post-job .dropdown {
    padding: 12px 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: #faf7fa;
    border: 1px solid #e8e6e8;
}

.tr-post-job .col-sm-3 label {
    margin-top: 12px;
    font-weight: normal;
    color: #2d3038;
}

.tr-post-job .dropdown-menu {
    min-width: 100%;
}

.tr-post-job .dropdown a {
    color: #2d3038;
    display: block;
}

.tr-post-job .dropdown i {
    float: right;
    margin-top: 2px;
}

.tr-post-job .dropdown a:hover {
    color: #00b365;
}

.tr-post-job .exprience-level,
.tr-post-job .job-category {
    margin-bottom: 20px;
}

.tr-post-job input[type="radio"] + label {
    cursor: pointer;
    margin-right: 25px;
    padding-left: 25px;
    position: relative;
    color: #2d3038;
    margin-bottom: 0;
    font-weight: normal;
}

.tr-post-job input[type="radio"] + label:last-of-type {
    margin-right: 0;
}

.tr-post-job input[type="radio"] {
    display: none;
}

.tr-post-job input[type="radio"] + label:before {
    position: absolute;
    top: 3px;
    left: 0;
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 100%;
}

.tr-post-job input[type="radio"] + label:before {
    border: 2px solid #00b365;
}

.tr-post-job input[type="radio"]:checked + label:before {
    border: 4px solid #00b365;
}

.tr-post-job .radio-type,
.tr-post-job .exprience {
    padding: 12px 0;
}

.tr-post-job .aegotiable {
    margin-top: 12px;
}

.tr-post-job .aegotiable span {
    margin-right: 5px;
}

.tr-post-job .job-description .jqte {
    padding: 12px 20px;
    min-height: 250px;
}

.job-description .characters-left {
    font-style: italic;
    display: block;
    margin-top: 5px;
}

.tr-post-job .job-description .row {
    margin-bottom: 30px;
}

.premium-post ul {
    margin-top: 30px;
    max-width: 380px;
}

.premium-post ul li span {
    float: right;
}

.premium-post ul li {
    margin-bottom: 25px;
}

.premium-post ul li:last-child {
    margin-bottom: 0;
}

.agreement label {
    position: relative;
    padding-left: 25px;
    display: block;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 40px;
}

.agreement label input[type="checkbox"] {
    width: 0;
    height: 0;
    display: none;
}

.agreement label:before {
    position: absolute;
    color: #00b365;
    font-size: 16px;
    content: "\f096";
    font-family: 'FontAwesome';
    left: 0;
    top: 0;
}

.agreement label.checked:before {
    content: "\f14a";
    color: #00b365;
}

.quick-rules .tr-title {
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 15px;
}

.quick-rules a {
    color: #7b7b7b;
}

.quick-rules a:hover {
    color: #00b365;
}

.quick-rules ul {
    margin-top: 30px;
}

.quick-rules ul li {
    position: relative;
    margin-bottom: 25px;
    padding-left: 15px;
}

.quick-rules ul li:before {
    position: absolute;
    content: "";
    top: 6px;
    left: 0;
    width: 8px;
    height: 8px;
    background-color: #00b365;
    border-radius: 100%;
}

.quick-rules ul li:last-child {
    margin-bottom: 0;
}

/* Tr Found */

.tr-found.section {
    padding: 80px;
}

.found-info {
    color: #b0b0b0;
}

.found-info h1 {
    color: #00b365;
    font-size: 110px;
    margin-bottom: 0;
}

.found-info h2 {
    color: #000;
    margin: 0 0 5px;
}

.found-info .btn.btn-primary {
    margin-top: 30px;
}

/* Tr Coming Soon */

.tr-coming-soon {
    color: #fff;
    text-transform: uppercase;
    /*background-image: url(../images/bg/banner.jpg);*/
}

.tr-coming-soon.section-before:before {
    background-color: rgba(0, 141, 239, 0.9);
}

.tr-coming-soon .logo {
    margin-bottom: 70px;
}

.tr-coming-soon .logo img {
    display: inline-block;
}

.tr-coming-soon h1 {
    font-size: 72px;
    margin: 0;
}

.tr-coming-soon h2 {
    font-size: 28px;
}

.countdown {
    margin: 60px 0 70px;
}

.countdown li {
    font-size: 14px;
    margin-right: 50px;
    display: inline-block;
}

.countdown li span {
    font-size: 80px;
}

.countdown li:last-child {
    margin-right: 0;
}

.tr-coming-soon .tr-form {
    width: 565px;
    position: relative;
    display: inline-block;
}

.tr-coming-soon .tr-form .form-control {
    height: 60px;
    color: #fff;
    border-radius: 30px;
    padding: 5px 30px;
    text-transform: uppercase;
    border-color: rgba(0, 141, 239, 0.9);
    background-color: rgba(0, 0, 0, 0.3);
}

.tr-coming-soon input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #fff;
}

.tr-coming-soon input::-moz-placeholder { /* Firefox 19+ */
    color: #fff;
}

.tr-coming-soon input:-ms-input-placeholder { /* IE 10+ */
    color: #fff;
}

.tr-coming-soon input:-moz-placeholder { /* Firefox 18- */
    color: #fff;
}

.tr-coming-soon .tr-form .form-control:focus {
    background-color: rgba(0, 0, 0, 0.5);
}

.tr-coming-soon .tr-form button {
    position: absolute;
    top: 13px;
    right: 30px;
    padding: 0;
    font-size: 24px;
    border: 0;
    background-color: transparent;
}

.tr-coming-soon .tr-form button:hover {
    right: 25px;
}

.tr-coming-soon .social {
    margin: 40px 0 20px;
}

.tr-coming-soon .social ul li {
    font-size: 16px;
    margin-right: 10px;
    display: inline-block;
}

.tr-coming-soon .social ul li:last-child {
    margin-right: 0;
}

.tr-coming-soon .social ul li a {
    color: #fff;
    padding: 5px;
}

.tr-coming-soon .copyright {
    text-transform: capitalize;
}

.tr-coming-soon .copyright a {
    color: #fff;
}

/* Gmap */

#gmap {
    height: 420px;
    margin-bottom: 30px;
    background-color: #eaeaea;
}

.contact-section .tr-form {
    margin-top: 30px;
}

.contact-section textarea.form-control {
    resize: none;
    height: 110px;
    padding: 12px 20px;
}

.contact-section button {
    margin-top: 10px;
}

.contact-section .info-list {
    margin: 40px 0;
}

.contact-section .info-list li {
    margin-bottom: 20px;
    text-transform: uppercase;
}

.contact-section .info-list li:last-child {
    margin-bottom: 0;
}

.contact-section .info-list li i {
    float: left;
    color: #00b365;
    margin-right: 15px;
    margin-top: 5px;
}

.info-list .media-body span {
    display: block;
    margin-bottom: 5px;
}

.info-list .media-body a {
    color: #7b7b7b;
}

.contact-section .social li {
    float: left;
    font-size: 22px;
    margin-right: 15px;
}

.contact-section .social li a {
    padding: 5px;
    color: #d1cfd1;
}

.contact-section .social li a:hover,
.info-list .media-body a:hover {
    color: #00b365;
}

/* Tr Pricing */

.pricing {
    background-color: #fff;
    padding: 40px 30px;
    color: #000;
    border-radius: 4px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
}

.pricing > span {
    font-size: 20px;
    display: block;
}

.pricing h1 {
    font-size: 72px;
    margin: 0 0 50px;
}

.pricing h1 sup {
    font-size: 30px;
    font-weight: 500;
    top: -25px;
}

.pricing h1 span {
    font-size: 14px;
    color: #000;
}

.pricing-list li {
    font-size: 14px;
    margin-bottom: 20px;
    text-transform: capitalize;
}

.pricing-list li i {
    color: #00b365;
    margin-right: 20px;
}

.pricing-list li i.fa-times {
    color: #dfdfdf;
}

.pricing .btn.btn-primary {
    color: #fff;
    padding: 8px 20px;
    margin-top: 30px;
    text-transform: capitalize;
    background-color: #253b4b;
    border-color: #253b4b;
}

.pricing .btn.btn-primary:before {
    background-color: #00b365;
}

.pricing .btn.btn-primary:hover {
    border-color: #00b365;
}

.pricing .most-popular {
    position: absolute;
    top: 25px;
    right: -60px;
}

.pricing .most-popular span {
    color: #fff;
    display: block;
    font-size: 14px;
    padding: 10px 70px;
    background-color: #00b365;
    -webkit-transform: rotate(40deg);
    -moz-transform: rotate(40deg);
    transform: rotate(40deg);
}

/* Tr Notification */

.tr-notification {
    padding: 80px;
    font-size: 24px;
}

.tr-notification .icon {
    font-size: 72px;
    color: #00b365;
    line-height: 0;
}

.tr-notification h1 {
    font-size: 30px;
    color: #00b365;
    margin: 20px 0 0;
}

.tr-notification .btn {
    margin-top: 35px;
}

/* Footer */

.footer-top {
    background-color: #fff;
}

.footer-widget h3 {
    font-size: 20px;
    color: #000;
    margin-bottom: 35px;
}

.footer-widget li {
    margin-bottom: 20px;
}

.footer,
.footer a {
    color: #858585;
}

.footer a:hover {
    color: #00b365;
}

.footer-bottom {
    padding: 25px 0 15px;
}

.footer .copyright {
    float: left;
}

.footer-social li {
    float: left;
}

.footer-social li + li {
    margin-left: 20px;
}

.footer-social li a {
    font-size: 16px;
    color: #00b365;
}

.footer-widget .form-control {
    margin: 30px 0 20px;
    background-color: transparent;
}

.footer-widget .btn {
    padding: 5px 20px;
    text-transform: capitalize;
}

/* ==========================================================================
 	Extra CSS
 ========================================================================== */

a, .btn:before, .job-social li a:before,
.navbar-right span, button.btn-primary,
.checkbox label:after, .remove-icon,
.breadcrumb-social span, .job-item .time,
.job-item .time:after, .button-share,
.button-share span, .tr-form button,
.form-control, .category-list .icon img,
.button-share span i {
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    transition: all 500ms;
}

/* for pop up */
/* For the media queries */

/* lg */
@media (min-width: 1400px) {

}

/* md */
@media (min-width: 992px) and (max-width: 1199px) {

    .tr-header .container {
        position: relative;
    }

    .tr-header .btn.btn-primary {
        position: absolute;
        right: 0;
        top: 56px;
        z-index: 99999999;
        padding: 8px 20px;
    }

    .tr-header .sign-in {
        margin-right: 0;
    }

    .tr-header .navbar-right {
        padding: 19px 0;
    }

    .app-content {
        padding: 0;
    }

    .tr-job-posted .col-md-3 {
        width: 33.33333333%;
    }

    .tr-job-posted .job-item .job-info {
        padding: 40px 45px 20px;
    }

    .tr-job-posted .item-overlay .job-info {
        padding: 45px 45px 40px;
    }

    .jobs-listing .two-column .job-info {
        padding: 40px 30px;
    }

    .testimonial-slider {
        max-width: 740px;
    }

    .profile-tabs.section {
        padding: 40px 20px;
    }

    .tr-sidebar .btn {
        font-size: 13px;
        padding: 8px 10px 7px;
    }

    .tr-sidebar .btn i {
        margin-right: 0;
    }

    .entry-content {
        padding: 28px 40px;
    }

    .profile-tabs.section {
        padding: 30px 15px;
    }

    .bookmark .col-md-4 {
        width: 50%;
    }

    .tr-post-job input[type="radio"] + label {
        margin-right: 15px;
    }

    .testimonial-info {
        padding: 40px 20px;
    }

    .list-menu > ul > li {
        width: 25%;
        margin-bottom: 1px;
    }

    .profile-tabs > li i {
        margin-right: 5px;
    }

    .account-info .fun-fact-icon {
        margin-right: 10px;
    }

    .account-content {
        padding: 35px 20px;
    }

}

@media (max-width: 991px) {

    .navbar-header {
        float: none;
    }

    .navbar-left, .navbar-right {
        float: none !important;
    }

    .navbar-toggle {
        display: block;
    }

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-nav {
        float: none !important;
    }

    .navbar-nav > li {
        float: none;
    }

    .collapse.in {
        display: block !important;
    }

    .topbar-left {
        margin-left: 0;
    }

    .navbar-brand.logo {
        display: none;
    }

    .navbar-brand.logo-2 {
        display: block;
        top: 0;
        left: 15px;
        margin-left: 0 !important;
    }

    .navbar-default .navbar-nav > li {
        margin-left: 0;
    }

    .tr-search {
        right: 95px;
    }

    .navbar-collapse {
        padding-top: 15px;
        border-top: 1px solid #fff;
    }

    .navbar-default .navbar-nav > li {
        margin: 0 0 1px;
    }

    .tr-dropdown-menu,
    .tr-dropdown-menu.left {
        min-width: inherit;
        width: 95%;
        left: 15px;
    }

    .navbar-default .navbar-nav > li > a {
        padding: 5px 10px;
    }

    .ad-info h1 {
        margin: 0;
    }

    .ad-info .btn.btn-primary {
        margin-top: 10px;
    }

    .ad-info .pull-left {
        text-align: right;
        float: none !important;
    }

    .testimonial-content {
        background-image: inherit;
    }

    .tr-header .btn.btn-primary {
        padding: 8px 20px 7px;
    }

    .apply-job .job-item .left-content,
    .apply-job .job-item .right-content {
        float: none;
    }

    .apply-job .job-item .right-content {
        margin-top: 10px;
    }

    .right-content span.applied {
        display: inline;
        text-align: inherit;
    }

    .role .left-content,
    .role .right-content {
        float: none;
        overflow: hidden;
    }

    .open-role .tr-title {
        margin-bottom: 5px;
    }

    .open-role .role {
        padding: 25px;
    }

    .contact-section .info-list .media-body {
        font-size: 13px;
    }
}

/* sm */
@media (min-width: 768px) and (max-width: 991px) {
    .tr-header .container {
        position: relative;
    }

    .tr-header .navbar-right {
        position: absolute;
        top: 0;
        right: 90px;
        padding: 10px 0;
    }

    .navbar-toggle {
        margin: 10px 0;
    }

    .tr-header .navbar-nav {
        max-height: 300px;
        overflow-x: hidden;
    }

    .banner-content h1,
    .page-title h1,
    .user-title h1 {
        color: white !important;
        font-size: 36px;
    }

    .banner-form input {
        width: 300px;
    }

    .banner-form .dropdown {
        width: 270px;
    }

    .tr-job-posted .job-item .job-info {
        padding: 40px 50px 20px;
    }

    .tr-job-posted .item-overlay .job-info {
        padding: 50px 50px 40px;
    }

    .two-column .company-logo {
        float: none;
        margin-bottom: 15px;
    }

    .jobs-listing .two-column .job-info {
        padding: 40px 20px;
    }

    .jobs-listing .two-column .job-meta li {
        margin-top: 5px;
    }

    .list-menu > ul > li {
        width: 33.3333%;
        margin-bottom: 1px;
    }

    .entry-content {
        padding: 19px 20px;
    }

    .entry-title {
        font-size: 22px;
    }

    .fun-fact {
        padding: 30px 15px;
    }

    .app-content {
        padding: 0;
    }

    .download-image,
    .download-info {
        display: block;
        margin-right: 0;
        text-align: center;
    }

    .download-image {
        margin-bottom: 15px;
    }

    .download-image img {
        margin-top: 0;
    }

    .download-app a {
        min-height: 170px;
        padding: 30px 15px;
    }

    .category-list li {
        font-size: 14px;
    }

    .testimonial-slider {
        max-width: 740px;
    }

    .profile-tabs.section {
        padding: 40px 20px;
    }

    .tr-sidebar .btn {
        padding: 8px 10px 7px;
    }

    .tr-sidebar .btn i {
        margin-right: 0;
    }

    .account-info .fun-fact {
        text-align: center;
        padding: 30px 18px;
    }

    .account-info .fun-fact-icon img {
        display: inline-block;
    }

    .account-info .fun-fact-icon {
        margin-right: 0;
        float: none;
    }

    .account-info .fun-fact h1 {
        margin: 10px 0;
    }

    .education-background li ul li {
        margin-right: 15px;
    }

    .personal-deatils .left {
        min-width: 150px;
    }

    .edit-resume .checkbox {
        margin-top: 0;
    }

    .testimonial-info {
        min-height: 240px;
    }

    .job-post-page .section,
    .contact-section .section {
        padding: 40px 25px;
    }

    .tr-coming-soon h1 {
        font-size: 60px;
    }

    .contact-section .info-list li i {
        margin-right: 5px;
    }

    .contact-section .social li {
        margin-right: 10px;
    }

    .found-info h1 {
        font-size: 80px;
    }

    .apply-job .job-info {
        padding: 30px 35px;
    }

    .close-account span {
        font-size: 20px;
    }

    .profile-tabs > li i {
        margin-right: 4px;
    }

    .contact-section .info-list {
        margin: 30px 0;
    }

    .info-list .media-body span {
        margin-bottom: 0;
    }

    .pricing {
        margin-bottom: 30px;
    }
}

/* xs */
@media (max-width: 767px) {

    .tr-header .navbar-default {
        padding-bottom: 15px;
    }

    .tr-header .navbar-right {
        padding: 0;
    }

    .tr-header .btn.btn-primary {
        position: absolute;
        top: 18px;
        right: 70px;
        padding: 5px 15px 4px;
    }

    .navbar-right .dropdown {
        float: left;
        padding: 0;
        margin-right: 10px;
    }

    .sign-in {
        float: left;
        margin-right: 0;
    }

    .navbar-right .dropdown-menu {
        top: 40px;
    }

    .navbar-toggle {
        margin: 15px;
    }

    .btn {
        padding: 8px 20px 6px;
        font-size: 12px;
    }

    .banner-content h1,
    .page-title h1,
    .user-title h1 {
        font-size: 36px;
    }

    .banner-content h2 {
        font-size: 20px;
    }

    .banner-form input,
    .banner-form .dropdown,
    .banner-form button.btn-primary {
        width: 100%;
    }

    .banner-form input {
        border-radius: 4px 4px 0 0;
        border-right: 0;
        border-bottom: 1px solid #cdcdcd;
    }

    .banner-form button.btn-primary {
        border-radius: 0 0 4px 4px;
    }

    .section-padding {
        padding: 50px 0;
    }

    .section-title h1 {
        font-size: 20px;
    }

    .category-list li {
        width: 50%;
    }

    .category-list li:nth-child(even) {
        border-right: 0;
    }

    .category-list.category-list-bottom li {
        border-bottom: 1px solid #e6e3e6;
    }

    .category-list.category-list-bottom li:last-child,
    .category-list.category-list-bottom li:nth-child(3) {
        border-bottom: 0;
    }

    .tr-steps h2 {
        font-size: 16px;
    }

    .tr-ad .ad-content {
        text-align: center;
        padding: 40px 15px;
    }

    .tr-ad .ad-content {
        background-image: inherit;
    }

    .tr-ad .pull-left {
        text-align: center;
    }

    .tr-ad .pull-right {
        float: none !important;
    }

    .ad-info h1 {
        margin: 0;
        font-size: 22px;
    }

    .testimonial-content {
        padding: 80px 0;
    }

    .tr-testimonial .media-left {
        min-width: 75px;
    }

    .testimonial-slider {
        font-size: 20px;
    }

    .entry-header.pull-left {
        float: none !important;
    }

    .entry-header img {
        width: 100%;
    }

    .entry-content {
        padding: 30px 20px;
    }

    .entry-title {
        font-size: 22px;
    }

    .job-tab .nav-tabs li {
        margin-right: 0;
    }

    .job-item {
        margin-bottom: 15px;
    }

    .footer-social.pull-right,
    .copyright {
        float: none !important;
    }

    .tr-breadcrumb .buttons i {
        font-size: 18px;
    }

    .tr-breadcrumb .buttons .btn {
        padding: 7px 15px 6px;
        font-size: 12px;
        margin-bottom: 10px;
    }

    .breadcrumb-logo {
        width: 150px;
    }

    .breadcrumb-logo,
    .user-image {
        width: 100px;
        height: 100px;
    }

    .tr-sidebar {
        margin-bottom: 30px;
    }

    .section {
        padding: 30px 15px;
    }

    .app-content {
        padding: 0;
    }

    .resume-last-updated {
        padding: 10px 15px;
    }

    .breadcrumb-social li {
        margin-right: 0;
    }

    .breadcrumb-social li a i {
        font-size: 14px;
        width: 35px;
        height: 35px;
        line-height: 35px;
    }

    .btn.button-cancle {
        margin-right: 5px;
    }

    .edit-resume .checkbox {
        margin-top: 0;
    }

    .two-column .job-info,
    .apply-job .job-info {
        padding: 30px 25px;
    }

    .personal-deatils .left {
        min-width: 110px;
    }

    .resume-info .icon {
        margin-right: 15px;
    }

    .banner-form form {
        display: block;
    }

    .list-menu > ul > li {
        width: 50%;
        margin-bottom: 1px;
        padding: 15px;
    }

    .tr-post-job .tr-title {
        margin-bottom: 20px;
    }

    .tr-post-job .col-sm-3 label {
        margin-top: 0;
    }

    .close-account h1 {
        font-size: 24px;
    }

    .close-account span {
        font-size: 18px;
    }

    .tr-coming-soon h1 {
        font-size: 30px;
    }

    .tr-coming-soon h2 {
        font-size: 18px;
    }

    .tr-coming-soon .tr-form {
        width: 290px;
    }

    .countdown li {
        font-size: 13px;
        margin-right: 15px;
    }

    .countdown li span {
        font-size: 35px;
    }

    .tr-coming-soon .tr-form .form-control {
        height: 45px;
        font-size: 12px;
    }

    .tr-coming-soon .tr-form button {
        top: 7px;
        font-size: 20px;
    }

    .countdown {
        margin: 50px 0;
    }

    .tr-notification {
        font-size: 18px;
        padding: 50px 15px;
    }

    .tr-notification .icon {
        font-size: 55px;
    }

    .tr-notification h1 {
        font-size: 24px;
    }

    #gmap {
        height: 300px;
    }

    .pricing h1 {
        font-size: 55px;
    }

    .pricing h1 sup {
        top: -20px;
    }

    .found-info h1 {
        font-size: 60px;
    }

    .found-info h2 {
        font-size: 20px;
    }

    .tr-found.section {
        padding: 50px 30px;
    }

    .tr-users {
        padding: 70px 0;
        background-image: inherit;
    }

    .job-find {
        margin-bottom: 100px;
    }

    .tr-users::before,
    .tr-users::after {
        width: 100%;
        height: 50%;
    }

    .tr-users::before {
        top: 0;
    }

    .tr-users::after {
        bottom: 0;
        top: inherit;
    }

    .tr-post-job .aegotiable {
        margin: 0 0 20px;
    }

    .personal-deatils .middle {
        margin-right: 15px;
    }

    .archived-job .job-info,
    .open-role .role {
        padding: 30px 20px;
    }

    .tr-download-app h1 {
        font-size: 22px;
    }

    .account-content {
        padding: 30px 14px;
    }

    .tr-testimonial-2 .testimonial {
        margin-bottom: 30px;
    }

    .tr-testimonial-2 {
        margin-bottom: 50px;
    }

    .testimonial-info {
        min-height: inherit;
    }

    .page-content {
        margin: 50px 0;
    }

}

/* XS Portrait */
@media (max-width: 479px) {

    .section-title h1 {
        font-size: 16px;
    }

    .tr-dropdown-menu,
    .tr-dropdown-menu.left {
        width: 92%;
    }

    .banner-content h1, .page-title h1,
    .user-title h1 {
        font-size: 26px;
    }

    .banner-content h2 {
        font-size: 18px;
    }

    .category-list li {
        width: 100%;
        border-right: 0;
    }

    .category-list.category-list-bottom li:nth-child(3) {
        border-bottom: 1px solid #e6e3e6;
    }

    .job-tab .nav-tabs > li a {
        margin-bottom: 5px;
    }

    .change-photo {
        text-align: center;
    }

    .change-photo .user-image {
        margin: 0;
        float: none;
    }

    .change-photo .user-image img {
        display: inline-block;
    }

    .change-photo .upload-photo {
        margin-top: 5px;
    }

    .account-info .social a {
        padding: 10px;
    }

    .account-info .social i {
        margin-top: 10px;
        margin-right: 5px;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .right-content span.applied {
        display: block;
        margin-top: 5px;
    }

    .list-menu > ul > li {
        width: 100%;
    }

    .two-column .company-logo {
        float: none;
        margin-bottom: 15px;
    }

    .buttons .btn {
        margin-bottom: 5px;
    }

    .additem:first-of-type .icon,
    .additem-1:first-of-type .icon {
        left: 0;
        top: 48px;
    }

    .job-item .left-content,
    .job-item .right-content,
    .apply-job .company-logo,
    .two-column .company-logo,
    .updated-info .pull-right,
    .updated-info .pull-left {
        float: none !important;
    }

}

.job-details .tr-cta {
    margin-bottom: 30px;
}

.jobs-listing .tr-cta {
    margin-bottom: 30px;
}

.ad-sidebar {
    margin: 10px 0;
}

.popup-ad-img img {
    width: 100%;
}

/* About us page */
/*FAQ  */
.faq {
    margin: 50px 0;
}

.input-group-addon img {
    height: 40px;
    width: auto;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-mention-wrapper {
  -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
       box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          -moz-font-feature-settings: 'tnum', "tnum";
       font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
.ant-mention-wrapper .ant-mention-editor {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  display: block;
  height: auto;
  min-height: 32px;
  padding: 0;
  line-height: 1.5;
}
.ant-mention-wrapper .ant-mention-editor::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor:-ms-input-placeholder {
  color: #bfbfbf;
}
.ant-mention-wrapper .ant-mention-editor::-webkit-input-placeholder {
  color: #bfbfbf;
}
.ant-mention-wrapper .ant-mention-editor:-moz-placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mention-wrapper .ant-mention-editor:-ms-input-placeholder {
  text-overflow: ellipsis;
}
.ant-mention-wrapper .ant-mention-editor:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mention-wrapper .ant-mention-editor:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor:focus {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mention-wrapper .ant-mention-editor-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor-disabled:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .ant-mention-editor[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper .ant-mention-editor[disabled]:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
textarea.ant-mention-wrapper .ant-mention-editor {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5;
  vertical-align: bottom;
  -webkit-transition: all 0.3s, height 0s;
  -moz-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}
.ant-mention-wrapper .ant-mention-editor-lg {
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
}
.ant-mention-wrapper .ant-mention-editor-sm {
  height: 24px;
  padding: 1px 7px;
}
.ant-mention-wrapper .ant-mention-editor-wrapper {
  height: auto;
  overflow-y: auto;
}
.ant-mention-wrapper.ant-mention-active:not(.disabled) .ant-mention-editor {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-mention-wrapper.disabled .ant-mention-editor {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mention-wrapper.disabled .ant-mention-editor:hover {
  border-color: #d9d9d9;
  border-right-width: 1px !important;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
}
.ant-mention-wrapper .public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner {
  height: auto;
  padding: 5px 11px;
  color: #bfbfbf;
  white-space: pre-wrap;
  word-wrap: break-word;
  outline: none;
  opacity: 1;
}
.ant-mention-wrapper .DraftEditor-editorContainer .public-DraftEditor-content {
  height: auto;
  padding: 5px 11px;
}
.ant-mention-dropdown {
  -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
       box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
          -moz-font-feature-settings: 'tnum', "tnum";
       font-feature-settings: 'tnum', "tnum";
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  min-width: 120px;
  max-height: 250px;
  margin-top: 1.5em;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-mention-dropdown-placement-top {
  margin-top: -0.1em;
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item {
  color: rgba(0, 0, 0, 0.25);
}
.ant-mention-dropdown-notfound.ant-mention-dropdown-item .anticon-loading {
  display: block;
  color: #1890ff;
  text-align: center;
}
.ant-mention-dropdown-item {
  position: relative;
  display: block;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: background 0.3s;
  -moz-transition: background 0.3s;
  transition: background 0.3s;
}
.ant-mention-dropdown-item:hover {
  background-color: #e6f7ff;
}
.ant-mention-dropdown-item.focus,
.ant-mention-dropdown-item-active {
  background-color: #e6f7ff;
}
.ant-mention-dropdown-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mention-dropdown-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mention-dropdown-item-selected,
.ant-mention-dropdown-item-selected:hover {
  color: rgba(0, 0, 0, 0.65);
  font-weight: bold;
  background-color: #f5f5f5;
}
.ant-mention-dropdown-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #e8e8e8;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
}
.ReactCrop:focus {
  outline: none;
}
.ReactCrop--disabled, .ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__image {
  display: block;
  max-width: 100%;
  touch-action: none;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  cursor: move;
  -webkit-box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  touch-action: none;
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}
.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after, .ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}
.ReactCrop__rule-of-thirds-vt::before, .ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}
.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: -moz-calc(100% / 3);
  left: calc(100% / 3);
}
.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: -moz-calc(100% / 3 * 2);
  left: calc(100% / 3 * 2);
}
.ReactCrop__rule-of-thirds-hz::before, .ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}
.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: -moz-calc(100% / 3);
  top: calc(100% / 3);
}
.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: -moz-calc(100% / 3 * 2);
  top: calc(100% / 3 * 2);
}
.ReactCrop__drag-handle {
  position: absolute;
}
.ReactCrop__drag-handle::after {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  outline: 1px solid transparent;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop .ord-nw::after {
  top: 0;
  left: 0;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-n::after {
  top: 0;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop .ord-ne::after {
  top: 0;
  right: 0;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-e::after {
  right: 0;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop .ord-se::after {
  bottom: 0;
  right: 0;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-s::after {
  bottom: 0;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop .ord-sw::after {
  bottom: 0;
  left: 0;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop .ord-w::after {
  left: 0;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop--new-crop .ReactCrop__drag-bar, .ReactCrop--new-crop .ReactCrop__drag-handle, .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s, .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (pointer: coarse) {
  .ReactCrop .ord-n,
.ReactCrop .ord-e,
.ReactCrop .ord-s,
.ReactCrop .ord-w {
    display: none;
  }
  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

/*# sourceMappingURL=ReactCrop.css.map */

